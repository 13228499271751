import { Box, Button, Container, Typography } from "@mui/material";
import apiClient from '../utils/apiClient';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);


const PreregisterWelcomePage = () => {

    const handleNext = async () => {
        try {
            // Call your backend to create a Checkout session
            const response = await apiClient.post('/preregister/');
    
            const stripe = await stripePromise;
            await stripe!.redirectToCheckout({
              sessionId: response.data.id,
            });
          } catch (error: any) {
            console.error(error.response?.data?.error || error.message || "An error occurred.");
          }
    };

    

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "40px" }}>
      {/* Title */}
      <Typography variant="h4" gutterBottom style={{ color: "#27755B", fontWeight: "bold" }}>
        Welcome to Quick Tee!
      </Typography>

      {/* Image */}
      <Box
        component="img"
        src='/golf.jpg'
        alt="Welcome"
        sx={{
          width: "100%",
          borderRadius: "8px",
          margin: "20px 0",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        }}
      />

      {/* Description */}
      <Typography variant="body1" paragraph>
        Thank you for joining Quick Tee! We're excited to launch on{" "}
        <strong>March 1st, 2025</strong>. By clicking "Next" and entering your payment info, you'll{" "}
        <strong>lock in your 50% lifetime discount</strong>.
      </Typography>
      <Typography variant="body2" style={{ color: "#555" }}>
        Don't worry—<strong>you won't be charged</strong> until we officially launch on March 1st.
      </Typography>

      {/* Next Button */}
      <Button
        variant="contained"
        size="large"
        onClick={handleNext}
        sx={{
          backgroundColor: "#27755B",
          color: "#FFFFFF",
          marginTop: "20px",
          "&:hover": {
            backgroundColor: "#1F5C46",
          },
        }}
      >
        Next
      </Button>
    </Container>
  );
};

export default PreregisterWelcomePage;

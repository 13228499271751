import {
    TextField,
    Box,
    Modal,
    Button
} from '@mui/material';

import apiClient from '../utils/apiClient';
import { useEffect, useState } from 'react';

interface FilterFormProps {
    onNewCourseRequest?: () => void;
    handleCloseModal: () => void;
    courseName?: string;
    courseLocation?: string;
    modalOpen?: boolean;
}

const CourseRequest: React.FC<FilterFormProps> = ({
    onNewCourseRequest,
    handleCloseModal,
    courseName = '',
    courseLocation = '',
    modalOpen = false,
}) => {

    const [newCourse, setNewCourse] = useState({ name: courseName, location: courseLocation });
    const [openModal, setOpenModal] = useState(modalOpen);

const handleSubmitNewCourse = async () => {
    try {
        const response = await apiClient.post('/course-request/', newCourse);
        if (onNewCourseRequest) {
            onNewCourseRequest();
        }
        handleCloseModal();
    } catch (err) {
        console.log("Failed to request course")
    }
};

useEffect(() => {
    setNewCourse({ name: courseName, location: courseLocation });
    setOpenModal(modalOpen);
}, [courseName, courseLocation, modalOpen]);

return (
    <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            p: 4,
            boxShadow: 24,
            borderRadius: '8px',
        }}>
            <h2>Request a Course</h2>
            <TextField
                label="Course Name"
                fullWidth
                value={newCourse.name}
                onChange={(e) => setNewCourse({ ...newCourse, name: e.target.value })}
                margin="normal"
            />
            <TextField
                label="Course Location"
                fullWidth
                value={newCourse.location}
                onChange={(e) => setNewCourse({ ...newCourse, location: e.target.value })}
                margin="normal"
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button onClick={handleCloseModal} sx={{ mr: 2 }}>Cancel</Button>
                <Button variant="contained" onClick={handleSubmitNewCourse}>Submit</Button>
            </Box>
        </Box>
    </Modal>
        );

    };

    export default CourseRequest;
import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Grid, CircularProgress } from '@mui/material';
import { LineChart } from '@mui/x-charts';
import apiClient from '../utils/apiClient';
import { format, eachDayOfInterval } from 'date-fns';
import CourseRequestsTable from '../components/CourseRequestsTable';

const AdminDashboard = () => {
    const [messageCounts, setMessageCounts] = useState<any[]>([]);
    const [statusCounts, setStatusCounts] = useState({});
    const [groupCounts, setGroupCounts] = useState({});
    const [totalFilters, setTotalFilters] = useState(0);
    const [averageFilters, setAverageFilters] = useState(0);
    const [courseRequests, setCourseRequests] = useState([]);
    const [coursesAdded, setCoursesAdded] = useState(0);
    const [loading, setLoading] = useState(true);

    function createGraphData(startDate: Date, data: any) {
        const endDate = new Date();
        const allDates = eachDayOfInterval({ start: startDate, end: endDate }).map(date => format(date, 'yyyy-MM-dd'));
        const completeData = allDates.map(date => {
            const dayData = data.find((d: { date: string; }) => d.date === date);
            return {
                date,
                count: dayData ? dayData.count : 0
            };
        });
        return completeData;
    }

    useEffect(() => {
        async function fetchStats() {
            setLoading(true);
            try {
                const response = await apiClient.get('/admin-stats/');

                const { daily_message_counts, subscription_status_counts, total_filters, average_filters_per_user,
                    open_course_requesets, courses_added_count, group_counts
                } = response.data
                const startDate = new Date(daily_message_counts[0].date);
                const completeData = createGraphData(startDate, daily_message_counts)
                setMessageCounts(completeData)
                setTotalFilters(total_filters)
                setAverageFilters(average_filters_per_user)
                setCourseRequests(open_course_requesets)
                setCoursesAdded(courses_added_count)
                setStatusCounts(subscription_status_counts)
                setGroupCounts(group_counts)
            } catch (error: any) {
                if (error.response && error.response.status === 401) {
                    console.error("Unauthorized access, redirecting to home page.");
                    window.location.href = "/dashboard";  // Redirect to home page
                } else {
                    console.error("Error fetching stats:", error);
                }
            } finally {
                setLoading(false);
            }
        }
        fetchStats();
    }, []);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Grid container spacing={3}>
                {/* Left Column */}
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                        Groups
                                    </Typography>
                                    {Object.entries(groupCounts as { [key: string]: string }).map(([key, val]) => (
                                        <div key={key}>
                                            <Typography sx={{ textTransform: 'capitalize' }}>
                                                {key.replace('_', ' ')} : {val}
                                            </Typography>
                                        </div>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* Subscriptions and Filters - Top Row */}
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                        Subscriptions
                                    </Typography>
                                    {Object.entries(statusCounts as { [key: string]: string }).map(([key, val]) => (
                                        <div key={key}>
                                            <Typography sx={{ textTransform: 'capitalize' }}>
                                                {key.replace('_', ' ')} : {val}
                                            </Typography>
                                        </div>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                        Filters
                                    </Typography>
                                    <Typography>Total Filters: {totalFilters}</Typography>
                                    <Typography>Average Filters per User: {averageFilters}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Messages Sent Line Chart - Below Subscriptions and Filters */}
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                        Messages Sent Daily
                                    </Typography>
                                    <LineChart
                                        xAxis={[
                                            {
                                                data: messageCounts.map((entry) => entry.date),
                                                label: 'Date',
                                                scaleType: 'point'
                                            },
                                        ]}
                                        series={[
                                            {
                                                data: messageCounts.map((entry) => entry.count),
                                                label: 'Messages Sent',
                                                color: 'blue',
                                                showMark: false,
                                            },
                                        ]}
                                        height={300}
                                        width={500}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Right Column - Course Requests */}
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                Course Requests
                            </Typography>
                            <Typography>Total Courses Added: {coursesAdded}</Typography>
                            <CourseRequestsTable courseRequests={courseRequests} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>

    );
};

export default AdminDashboard;

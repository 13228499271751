import {
    TextField,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CourseRequest from './CourseRequest';

interface FilterFormProps {
    newFilter: any;
    setNewFilter: (filter: any) => void;
    onNewCourseRequest?: () => void;
}

const CourseSearch: React.FC<FilterFormProps> = ({
    newFilter,
    setNewFilter,
    onNewCourseRequest
}) => {

    const [courses, setCourses] = useState([]);
    const [newCourse, setNewCourse] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const filter = createFilterOptions();
    const baseURL = process.env.REACT_APP_BACK_END_URL;


const fetchCourses = async () => {
    try {
        const response = await axios.get(`${baseURL}/courses/`, {
        });
        setCourses(response.data);
    } catch (error) {
        console.error("Error fetching courses:", error);
    }
};

const handleOpenModal = (course_name: string) => {
    setNewCourse(course_name);
    setOpenModal(true);
}


const handleCloseModal = () => {
    setOpenModal(false)
};

useEffect(() => {
    fetchCourses();
}, []);

return (
    <div>
        <Autocomplete
            options={courses}
            getOptionLabel={(course: any) => {
                if (typeof course === 'string') {
                    return course;
                }
                if (course.inputValue) {
                    return course.inputValue;
                }
                return course.formatted_name;
            }}
            value={courses.find((course: any) => course.id === newFilter.course) || null}
            onChange={(event, newValue) => {
                if (newValue && newValue.inputValue) {
                    handleOpenModal(newValue.inputValue);
                } else if (newValue) {
                    setNewFilter({ ...newFilter, course: newValue.id });
                }
            }}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <li key={key} {...optionProps}>
                        {option.formatted_name}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required
                    label="Select Course"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
            )}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.formatted_name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        formatted_name: `Request to add the course "${inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
        />

        {/* Modal for requesting a missing course */}
        <CourseRequest onNewCourseRequest={onNewCourseRequest} handleCloseModal={handleCloseModal} courseName={newCourse} modalOpen={openModal} />
    </div>
        );

    };

    export default CourseSearch;
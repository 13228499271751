import React, { createContext, useContext, useState } from 'react';

interface AccountContextProps {
    subscriptionStatus: string | null;
    firstName: string;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    referralCode: string;
    group: string | null;
    setSubscriptionStatus: React.Dispatch<React.SetStateAction<string | null>>;
    setFirstName: React.Dispatch<React.SetStateAction<string>>;
    setLastName: React.Dispatch<React.SetStateAction<string | null>>;
    setEmail: React.Dispatch<React.SetStateAction<string | null>>;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string | null>>;
    setReferralCode: React.Dispatch<React.SetStateAction<string>>;
    setGroup: React.Dispatch<React.SetStateAction<string | null>>;
  }
  
  export const AccountContext = createContext<AccountContextProps | undefined>(undefined);
  
  export const AccountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [referralCode, setReferralCode] = useState<string>('');
    const [group, setGroup] = useState<string | null>(null);
  
    return (
      <AccountContext.Provider
        value={{
          subscriptionStatus,
          firstName,
          lastName,
          email,
          phoneNumber,
          referralCode,
          group,
          setSubscriptionStatus,
          setFirstName,
          setLastName,
          setEmail,
          setPhoneNumber,
          setReferralCode,
          setGroup
        }}
      >
        {children}
      </AccountContext.Provider>
    );
  };
  export const useAccount = () => {
    const context = useContext(AccountContext);
    if (!context) {
      throw new Error('useAccount must be used within an AccountProvider');
    }
    return context;
  };
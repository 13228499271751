import React from 'react';
import { Box } from '@mui/material';

import TopMenuBar from './TopMenuBar';
import { isUserSignedIn } from '../utils/authUtils';
import { Outlet } from 'react-router-dom';


const AppLayout = ({ children }: { children?: React.ReactNode }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                overflowX: 'hidden', // Prevent horizontal overflow on small screens
            }}
        >
            {/* Top Menu Bar */}
            <TopMenuBar
                showMenuIcon={false}
                isLoggedIn={isUserSignedIn()}
            />

            {/* Main Layout with Content */}
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    mt: '64px',
                    backgroundColor: '#f9fafb',
                    flexDirection: { xs: 'column', md: 'row' }, // Stack on mobile, side-by-side on desktop
                    overflowX: 'hidden', // Prevent horizontal scrolling
                }}
            >

                {/* Main Content */}

                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    {children}
                    <Outlet />
                </Box>

            </Box>
        </Box>
    );
};

export default AppLayout;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Filters from './pages/Filters';
import Account from './pages/Account';
import Landing from './pages/Landing';
import TermsAndConditions from './pages/TermsAndConditions';
import Subscription from './pages/Subscription';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NewUserSurvey from './pages/NewUserSurvey';
import Subscribe from './components/Subscribe';
import ReactGA from 'react-ga4';

import AppLayout from './components/AppLayout'; // The layout containing the side menu

import { AuthProvider } from './contexts/AuthContext';
import { AccountProvider } from './contexts/AccountContext';


import { createTheme, ThemeProvider } from '@mui/material/styles';
import AdminDashboard from './pages/AdminDashboard';
import Unsubscribe from './pages/Unsubscribe';
import PreregisterSurvey from './pages/PreregisterSurvey';
import PrelaunchRegistered from './pages/PrelaunchRegistered';
import PreregisterWelcomePage from './pages/PreregisterWelcome';
import NotFound from './pages/NotFound';
import PublicLayout from './components/PublicLayout';

const theme = createTheme({
  palette: {
    primary: {
      main: '#27755B',
      light: '#72AD9A',
      contrastText: '#FFF',
      dark: '#013F2B',
    },
    secondary: {
      main: '#2C5072',
      light: '#748EAA'
    }
  },
});

const TRACKING_ID = "G-Z3NWC7731G";
ReactGA.initialize(TRACKING_ID);

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AccountProvider>
          <Router>
            <Routes>
              <Route path="*" element={<NotFound />} />

              <Route element={<PublicLayout />}>
                <Route path="/" element={<Landing />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Route>

              <Route element={<AppLayout />}>
                <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
                <Route path="/filters" element={<ProtectedRoute element={<Filters />} />} />
                <Route path="/account" element={<ProtectedRoute element={<Account />} />} />
                <Route path="/subscription" element={<ProtectedRoute element={<Subscription />} />} />
                <Route path="/admin" element={<ProtectedRoute element={<AdminDashboard />} />} />
                <Route path="/subscribe" element={<ProtectedRoute element={<Subscribe />} />} />
                <Route path="/new-user-survey" element={<ProtectedRoute element={<NewUserSurvey />} />} />
                <Route path="/unsubscribe" element={<ProtectedRoute element={<Unsubscribe />} />} />
                <Route path="/preregister" element={<ProtectedRoute element={<PreregisterSurvey />} />} />
                <Route path="/preregistered" element={<ProtectedRoute element={<PrelaunchRegistered />} />} />
                <Route path="/welcome" element={<ProtectedRoute element={<PreregisterWelcomePage />} />} />
              </Route>
            </Routes>
          </Router>
        </AccountProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
import React, { useState, useEffect } from 'react';
import apiClient from '../utils/apiClient';
import { Snackbar, TablePagination, useMediaQuery } from '@mui/material';
import logo from '../assets/logo.png';
import { Box, Typography, Card, CardContent, Grid, Button } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useTheme } from '@mui/material/styles';
import Subscribe from '../components/Subscribe';
import CourseRequestsTable from '../components/CourseRequestsTable';
import { useAccount } from '../contexts/AccountContext';


const Dashboard: React.FC = () => {
  const [hasSubscription, setHasSubscription] = useState(false); // Track subscription status
  const [name, setName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [courseRequests, setCourseRequests]: any = useState([]);
  const [teeTimeAlerts, setTeeTimeAlerts]: any = useState([]);
  const [alertRowsPerPage, setAlertRowsPerPage] = useState(3);
  const [alertPage, setAlertPage] = useState(0);
  const { subscriptionStatus, firstName, referralCode } = useAccount();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const appURL = process.env.REACT_APP_BASE_URL;

  const handleAlertChangePage = (event: unknown, newPage: number) => setAlertPage(newPage);
  const handleAlertChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlertRowsPerPage(parseInt(event.target.value, 10));
    setAlertPage(0);
  };

  const handleCopyReferralCode = () => {
    navigator.clipboard.writeText(`${appURL}/register?referral=${referralCode}`);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const displayedAlerts = teeTimeAlerts.slice(alertPage * alertRowsPerPage, alertPage * alertRowsPerPage + alertRowsPerPage);


  useEffect(() => {
    const fetchCourseRequests = async () => {
      try {
        const response = await apiClient.get('/course-request/');
        setCourseRequests(response.data);
      } catch (error) {
        console.error('Error fetching course requests data:', error);
      } finally {
      }
    };

    setName(firstName);
    setHasSubscription(subscriptionStatus == 'active' || subscriptionStatus == 'trialing');
    
    const fetchSentMessages = async () => {
      try {
        const response = await apiClient.get('/sent-messages/');
        setTeeTimeAlerts(response.data);
      } catch (error) {
        console.error('Error fetching course requests data:', error);
      } finally {
      }
    };

    fetchSentMessages();
    fetchCourseRequests();
  }, []); // Fetch data on component mount

  return (
    <Box p={3}>
      {(
      hasSubscription ?(<Box mb={4} sx={{ textAlign: 'center', bgcolor: 'primary.light', color: 'white', p: 2, borderRadius: 2 }}>
        <Typography variant="h4">Welcome, {name}!</Typography>
      </Box>) : (<Box 
      mb={4} 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        background: 'linear-gradient(to right bottom, #2C5072, #748EAA)',
        color: 'white', 
        p: 2, 
        borderRadius: 2 
      }}
    >
      {/* Left section with text and button */}
      <Box sx={{ flex: 1, textAlign: 'left' }}>
        <Typography variant={isMobile ? "h6" : "h5"}>
          Hey {name}, don't miss out — start getting the tee times you want by clicking the button below!
        </Typography>
        <Subscribe />
      </Box>
    
      {/* Right section with image */}
      <Box sx={{ ml: 2 }}>
        <img 
          src="subscribe.png" 
          alt="Golf illustration" 
          style={{ maxWidth: isMobile ? '100px' : '200px', 
            height: 'auto', 
            borderRadius: '8px' }} 
        />
      </Box>
    </Box>))
      }

      <Grid container spacing={3}>

        {/* Course Requests Status */}
        <Grid item xs={12} md={4}>
        <CourseRequestsTable 
        courseRequests={courseRequests}
        />
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ borderRadius: '20px', bgcolor: 'primary.main' }}>
            <CardContent>
              <img
                src={logo}
                alt="Quick Tee Logo"
                style={{ width: '100%', height: 'auto', margin: '20px auto', display: 'block' }}
              />
            </CardContent>
          </Card>
        </Grid>
        {/* Tee Time Alerts Feed */}
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', pt: 1, borderRadius: '20px' }}>
            <CardContent sx={{ p: 0 }}>
            <Typography variant="h6" sx={{ pl: 2, pt: 3, pb: 1 }}>Recent Tee Time Alerts</Typography>
              {teeTimeAlerts.length > 0 ? (

                <TableContainer sx={{ flexGrow: 1, height: '100%' }}>
                  <Table aria-label="simple table" sx={{ height: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Date</TableCell>
                        <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Course</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedAlerts.map((alert: any, index: any) => {
                        const sentAtFormatted = new Intl.DateTimeFormat('en-US', {
                          month: 'numeric',
                          day: 'numeric',
                          year: '2-digit',
                          hour: 'numeric',
                          minute: '2-digit',
                          hour12: true
                        }).format(new Date(alert.sent_at));
                        return (

                          <TableRow
                            key={index}
                          >
                            <TableCell component="th" scope="row">
                              {sentAtFormatted}
                            </TableCell>
                            <TableCell>
                              {alert.course_name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography sx={{textAlign:'center', color:'info.main'}}>No recent alerts</Typography>
              )}
              {courseRequests.length > 0 && <TablePagination
                component="div"
                count={teeTimeAlerts.length}
                page={alertPage}
                onPageChange={handleAlertChangePage}
                rowsPerPage={alertRowsPerPage}
                onRowsPerPageChange={handleAlertChangeRowsPerPage}
                rowsPerPageOptions={[]}
              />}
            </CardContent>
          </Card>
        </Grid>

        {/* Referral Program Prompt */}
        <Grid item xs={12}>
          <Card sx={{ textAlign: 'center', p: 3, borderRadius: '20px' }}>
            <CardContent>
              <Typography variant="h5">Refer a Friend</Typography>
              <Typography>
                Earn a free month by referring friends! When they sign up, you both benefit.
              </Typography>
              <Button variant="contained" color="secondary" sx={{ mt: 2 }} onClick={handleCopyReferralCode}>
                Share Referral Link
              </Button>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Referral code copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Box>
  );
};

export default Dashboard;
